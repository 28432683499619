import React from 'react'
import PasswordUpdated from '../components/PasswordUpdated'
import SEO from '../components/SEO'

export default () => (
  <div style={{ backgroundColor: '#f5f5f5' }}>
    <SEO title='Password Update Successful' />
    <PasswordUpdated />
  </div>
)
