import React, { Component } from 'react'
import { Link } from 'gatsby'
import {
  FlatLogo,
  Section,
  Container,
  Card,
  CardHeader,
  CardContent,
  Button
} from 'sqrl-design-system'

class PasswordUpdated extends Component {
  render() {
    return (
      <Section>
        <Container>
          <Card
            style={{ maxWidth: 600, marginLeft: 'auto', marginRight: 'auto' }}
          >
            <CardHeader className="center-logo" style={{ padding: '30px 0' }}>
              <FlatLogo fill="#9013fe" style={{ marginRight: 5 }} />
              <span style={{ color: '#202733' }}>|{'  '}Workforce</span>
            </CardHeader>
            <CardContent style={{ padding: 0 }}>
              <div className="password-updated-container">
                <img
                  src="/images/icons/password-updated-shield.svg"
                  alt="password updated security shield"
                />
                <h1>Password Updated</h1>
                <p>
                  Please use your new password the next time you log into your
                  account.
                </p>
                <Link to="signup" title="Signup" className="full-width">
                  <Button isColor="primary" className="is-rounded full-width">
                    Return to Sign In
                  </Button>
                </Link>
              </div>
            </CardContent>
          </Card>
        </Container>
      </Section>
    )
  }
}

export default PasswordUpdated
